/* .mainBanner {
    width: 100%;
    max-height: 100%;
    height: 747px;
} */

.headerTitle {
    font-size: 4rem;
    line-height: 1.5 !important;
}

.titleDevelop, .titleBusiness {
    color: #0876C1 !important;
}

.titleAmazing {
    color: #EB4747 !important;
}

.bannerContent {
    position: absolute;
    top: 200px;
    /* right: 0;
    width: 100%; */
}

/* @media only screen  and (max-width: 768px) {
    .mainBanner {
        width: 100%;
        height: 100%;
    }

    .headerTitle {
        font-size: 3rem;
    }
} */

@media only screen  and (max-width: 767px) {
    .mainBanner {
        width: 100%;
        height: 100%;
    }

    .headerTitle {
        font-size: 2.5rem !important;
    }

    .companyBannerBg {
        height: 60vh !important;
    }

    .bannerContent {
        top: 160px !important;
    }
}

@media only screen and (min-width: 300px) and (max-width: 375px) {
    .headerTitle {
        font-size: 2rem !important;
    }

    .companyBannerBg {
        height: 70vh !important;
    }

    .bannerContent {
        top: 160px !important;
    }
}

@media only screen and (min-width: 376px) and (max-width: 390px) {
    .headerTitle {
        font-size: 2rem !important;
    }

    .companyBannerBg {
        height: 60vh !important;
    }

    .bannerContent {
        top: 160px !important;
    }
}

@media only screen and (min-width: 391px) and (max-width: 412px) {
    .headerTitle {
        font-size: 2.9rem !important;
    }
}