.sectionTitle {
  color: #dd2723;
}

.contactForm input {
  height: 3.3rem;
}

.contactForm {
  width: 570px;
  text-align: center;
  margin: 0 auto;
}

.mobileNo input[type="number"]::-webkit-inner-spin-button,
.mobileNo input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .indianOfficeMap,
  .japanOfficeMap {
    order: 2;
  }

  .indianOfficeAddress,
  .japanOfficeAddress {
    order: 1;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .indianOfficeMap {
    order: 2;
  }

  .indianOfficeAddress {
    order: 1;
  }

  .contactForm {
    width: 100%;
    /* margin: 0; */
  }
}
