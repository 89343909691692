.sectionTitle {
    color: #DD2723;
}

.leadersImage {
    width: 100%;
}

@media only screen  and (max-width: 768px) {
    .leadersImage {
        width: 80%;
    }
}