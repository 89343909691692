.site-logo {
    width: 250px;
    height: auto;
}

.header-menu {
    cursor: pointer;
    text-decoration: none !important;
    color: #383745 !important;
    padding: 0rem 1.5rem !important;
}

.header-menu:hover {
    color: #0876C1 !important;
}

.MuiDrawer-paper {
    width: 100% !important;
    display: grid !important;
    /* justify-content: center; */
    /* align-items: center; */
    text-align: center;
}

.header-menu-mobile a {
    display: block;
    padding: 24px 20px !important;
    font-size: 1.2rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .site-logo {
        width: 200px;
    }

    .header-menu {
        padding: 0rem .8rem !important;
    }
}

@media only screen and (max-width: 767px) {
    .site-logo {
        width: 250px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 390px) {
    .site-logo {
        width: 200px;
    }
}

