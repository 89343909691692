.ServicesTitle {
    font-size: 1.25rem !important;
}

.sectionTitle {
    color: #DD2723;
}

*,
*:before,
*:after {
    position: relative;
    transition: all 0.35s;
}

.cardTitle, .contentCardHover {
    color: #ffffff;
}

.cardWrapper .servicesCard {
    padding: 1rem;
    overflow: hidden;
    min-height: 100%;
    height: 400px;
}

.cardWrapper .servicesCard:before, .cardWrapper .servicesCard:after {
    content: "";
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.cardWrapper .servicesCard:before {
    height: calc(100% + 30px);
    background-size: cover;
}

.cardWrapper .servicesCard:after {
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 -80px 90px -15px rgba(0, 0, 0, 0.75);
}

.cardWrapper .servicesCard .servicesCardContent {
    top: 100%;
    z-index: 2;
}

.cardWrapper .servicesCard .servicesCardContent .cardTitle:first-of-type {
    display: grid;
    margin-bottom: 10px;
    transform: translateY(-100%);
}

.cardWrapper .servicesCard:hover:after {
    background: rgba(0, 0, 0, 0.5);
}

.cardWrapper .servicesCard:hover:before {
    top: -30px;
}

.cardWrapper .servicesCard:hover .servicesCardContent {
    top:50%;
    transform: translateY(-50%);
}

.cardWrapper .servicesCard:hover .servicesCardContent .cardTitle:first-of-type {
    transform: translateY(0%);
}

.cardWrapper .servicesCard:hover .servicesCardContent .contentCardHover:nth-of-type(2) {
    opacity: 1;
}

.cardWrapper .servicesCard:nth-of-type(1):before {
    background-image: url('../../assets/mobile-application-development-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(2):before {
    background-image: url('../../assets/microsoft-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(3):before {
    background-image: url('../../assets/ai-ml-services-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(4):before {
    background-image: url('../../assets/open-source-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(5):before {
    background-image: url('../../assets/data-spider-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(6):before {
    background-image: url('../../assets/SEO-services-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(7):before {
    background-image: url('../../assets/website-maintence-support-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(8):before {
    background-image: url('../../assets/windows-application-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(9):before {
    background-image: url('../../assets/responsive-design-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(10):before {
    background-image: url('../../assets/desktop-applications-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(11):before {
    background-image: url('../../assets/client-server-application-bg.png');
}

.cardWrapper .servicesCard:nth-of-type(12):before {
    background-image: url('../../assets/application-re-engineering-support-bg.png');
}

@media only screen  and (max-width: 767px) {
    .cardWrapper .servicesCard {
        margin-bottom: 20px;
        margin-left: 20px;
    }
}

