.ServicesTitle {
    font-size: 1.25rem !important;
}

.sectionTitle {
    color: #DD2723;
}

.btnExploreAllOurServices {
    text-decoration: none !important;
}

*,
*:before,
*:after {
    position: relative;
    transition: all 0.35s;
}

.cardTitle, .contentCardHover {
    color: #ffffff;
}

.con .card {
    padding: 1rem;
    overflow: hidden;
    min-height: 100%;
    height: 400px;
}

.con .card:before, .con .card:after {
    content: "";
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.con .card:before {
    height: calc(100% + 30px);
    background-size: cover;
}

.con .card:after {
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 -80px 90px -15px rgba(0, 0, 0, 0.75);
}

.con .card .card_s {
    top: 100%;
    z-index: 2;
}

.con .card .card_s .cardTitle:first-of-type {
    display: grid;
    margin-bottom: 10px;
    transform: translateY(-100%);
}

.con .card:hover:after {
    background: rgba(0, 0, 0, 0.5);
}

.con .card:hover:before {
    top: -30px;
}

.con .card:hover .card_s {
    top:50%;
    transform: translateY(-50%);
}

.con .card:hover .card_s .cardTitle:first-of-type {
    transform: translateY(0%);
}

.con .card:hover .card_s .contentCardHover:nth-of-type(2) {
    opacity: 1;
}

.con .card:nth-of-type(1):before {
    background-image: url('../../assets/artifical-intelligence-bg.png');
}

.con .card:nth-of-type(2):before {
    background-image: url('../../assets/cloud-computing-bg.png');
}

.con .card:nth-of-type(3):before {
    background-image: url('../../assets/app-development-bg.png');
}

.con .card:nth-of-type(4):before {
    background-image: url('../../assets/web-experts-bg.png');
}


@media only screen  and (max-width: 768px) {
    .con .card {
        margin-bottom: 20px;
    }
}


