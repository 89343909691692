* {
  box-sizing: border-box !important;
  font-family: "outfit-regular" !important;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

p {
  line-height: 2 !important;
}

@font-face {
  font-family: "outfit-regular";   /*Can be any text*/
  src: local("Outfit-Regular"),
    url("./fonts/Outfit-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "outfit-medium";   /*Can be any text*/
  src: local("Outfit-Medium"),
    url("./fonts/Outfit-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "outfit-semibold";   /*Can be any text*/
  src: local("Outfit-SemiBold"),
    url("./fonts/Outfit-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "outfit-bold";   /*Can be any text*/
  src: local("outfit-Bold"),
    url("./fonts/Outfit-Bold.ttf") format("truetype");
}
