.ourProduct {
    max-width: 100%;
    width: 80%;
}

@media only screen  and (max-width: 768px) {
    .ourProduct {
        width: 80%;
        margin-top: 32px;
    }
}