/* .mainBanner {
    width: 100%;
    max-height: 100%;
    height: 747px;
} */

.servicesBannerBg {
    width: 100%;
    height: 80vh;
}

.headerTitle {
    font-size: 4rem;
    line-height: 1.3 !important;
}

.titleDevelop, .titleBusiness {
    color: #0876C1 !important;
}

.titleAmazing {
    color: #EB4747 !important;
}

.bannerContent {
    position: absolute;
    top: 200px;
}

.servicesBannerContent {
    width: 500px;
}

@media only screen and (min-width: 1300px) and (max-width: 1440px) {
    .servicesBannerBg {
        height: 100vh;
    }

    .bannerContent {
        top: 150px;
    }
}

@media only screen  and (max-width: 767px) {
    .mainBanner {
        width: 100%;
        height: 100%;
    }

    .servicesBannerTitle {
        font-size: 3rem !important;
    }

    .servicesBannerContent {
        width: 100%;
    }

    .bannerContent {
        top: 160px !important;
    }

    .servicesBannerBg {
        height: 70vh !important;
    }
}

@media only screen and (min-width: 300px) and (max-width: 375px) {
    .servicesBannerTitle {
        font-size: 2.5rem !important;
    }

    .servicesBannerBg {
        height: 90vh !important;
    }

    .bannerContent {
        top: 160px !important;
    }
}

@media only screen and (min-width: 376px) and (max-width: 390px) {
    .servicesBannerTitle {
        font-size: 2.5rem !important;
    }

    .servicesBannerBg {
        height: 80vh !important;
    }

    .bannerContent {
        top: 160px !important;
    }
}

@media only screen and (min-width: 391px) and (max-width: 412px) {
    .servicesBannerTitle {
        font-size: 2.9rem !important;
    }
}