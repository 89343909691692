.careerForm {
    width: 450px;
    text-align: center;
    margin: 0 auto;
}

.careerForm input {
    height: 3.3rem;
} 

.mobileNo input[type=number]::-webkit-inner-spin-button, 
.mobileNo input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media only screen and (max-width: 767px) {
    .careerForm {
        width: 100%;
    }
}