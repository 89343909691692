.primaryContainedButton {
    background-color: #0876C1 !important;
    border-radius: 40px !important;
    text-transform: inherit !important;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
}

.primaryOutlinedButton {
    /* background-color: #0876C1; */
    border-radius: 40px !important;
    text-transform: inherit !important;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
    border: 2px solid #0876C1 !important;
    border-color: #0876C1 !important;
    color: #0876C1 !important;
}

.secondaryButton {
    background-color: #383745 !important;
    border-radius: 40px !important;
    text-transform: inherit !important;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
}

.getStartedButton {
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    text-transform: inherit !important;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
    border-width: 2px !important;

}