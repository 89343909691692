.sectionTitle {
    color: #DD2723;
}

.backgroundBg {
  width: 80%;
}

.wrapper img {
  width: 300px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 230px 330px;
}

.btn.one {
  grid-column: 3;
}
.btn.four {
  grid-column: 3;
  grid-row: 3;
}

.btn.two {
  grid-column: 2;
  grid-row: 2;
}

.btn.three {
  grid-column: 4;
  grid-row: 2;
}

@media only screen and (min-width: 1025px) and (max-width: 1140px) {
  .btn.one, .btn.two, .btn.four, .btn.three {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .wrapper img {
    width: 250px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .btn.one, .btn.two, .btn.four, .btn.three {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper  {
      display: block !important;
      height: auto !important;
  }

  .btn.four, .btn.two, .btn.three {
      padding-top: 0px !important;
  }

  .wrapper img {
      width: 100% !important;
  }
}