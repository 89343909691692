.sectionTitle {
    color: #DD2723;
}

.WhyRajasriicon {
    width: 60px;
    height: 60px;
}

.arrowIcon {
    background-color: #383745;
    color: #ffffff;
    border-radius: 100px;
    width: 40px;
    height: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .wrapper img {
        width: 200px;
    }
}