.footerLinks {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footerLinks li {
    margin-bottom: 1.2rem;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #ffffff;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .secondary-footer-menu {
    color: #ffffff;
    padding: 12px 20px !important;
    text-decoration: none !important;
}

  @media only screen and (max-width: 768px) {
    .footerBottom {
      flex-direction: column;
      text-align: center;
    }
  }