.challengesBg {
    max-width: 100%;
    width: 100%;
}

.sectionTitle {
    color: #DD2723;
    font-size: 2.25rem;
}

.animateNumbers {
    padding-right: 6px;
    display: inline-flex !important;
}