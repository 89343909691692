.sectionTitle {
    color: #DD2723;
}

.arrowIcon {
    background-color: #383745;
    color: #ffffff;
    border-radius: 100px;
    width: 40px;
    height: 40px;
}

.titleWeWork {
    color: #0876C1;
    font-size: 1.5rem !important;
    padding-left: 1.5rem;
}

